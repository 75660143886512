import React from "react"

import Slider from "react-slick"

import ArticleCard from "../Reusable/ArticleCard"

import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"

export default function ArticleSlider({ articles }) {
  if (!articles) return null

  return (
    <Slider arrows={false} dots={true} infinite={true}>
      {articles.edges.map(article => {
        const { id, uri, slug, title, Magazines, acfPageColour } = article.node
        const { featuredImage = "" } = Magazines
        return (
          <div className="slide" key={id}>
            <ArticleCard
              link={uri}
              slug={slug}
              backgroundImage={featuredImage}
              readingTime={false}
              title={title}
              colour={acfPageColour.pageColour}
              texture={article.node.terms.nodes[0].slug < 2024 ? true : false}
            />
          </div>
        )
      })}
    </Slider>
  )
}
