import React from "react"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/styles"
import styles from "./styles"
import ArticleSlider from "./slider"

export default function RelatedArticles({ articles }) {
  const useStyles = makeStyles(theme => styles(theme))

  const classes = useStyles()

  return (
    <div className={`relatedArticles ${classes.relatedArticles} `}>
      <Typography component="h2" variant="h2">
        Related Stories
      </Typography>
      <ArticleSlider articles={articles} />
    </div>
  )
}
