export default theme => ({
  // eslint-disable-line
  relatedArticles: {
    backgroundColor: "var(--page-accent)",
    paddingTop: 65,
    "& > .MuiTypography-root": {
      textTransform: "uppercase",
      marginBottom: 65,
      color: "var(--page-accent) !important",
      "-webkit-text-stroke-width": "1px",
      "-webkit-text-stroke-color": "var(--uow-royal-blue)",
    },
    "& .slick-slider": {
      paddingBottom: 70,
      margin: 0,
      [theme.breakpoints.up("md")]: {
        width: "calc(41.666% + 12px)",
      },
      "& .slick-list": {
        overflow: "visible",
        "& .slick-track": {
          "& .slick-slide": {
            paddingRight: 24,
          },
        },
      },
      "& .slick-dots": {
        bottom: 0,
        left: 0,
        transformOrigin: "0 50%",
        transform: "scaleX(1.25)",
        width: "auto",
        "& li": {
          fontSize: 0,
          lineHeight: 0,
          width: "auto",
          height: "auto",
          padding: 0,
          "& button": {
            width: 10,
            height: 10,
            border: "2px solid var(--uow-royal-blue)",
            fontSize: 0,
            lineHeight: 0,
            padding: 0,
            backgroundColor: "transparent",
            transition: "background-color .3s ease-in-out",
            transform: "rotate(45deg)",
            "&:before": {
              display: "none",
            },
          },
          "&.slick-active": {
            "& button": {
              backgroundColor: "var(--uow-royal-blue)",
            },
          },
        },
      },
    },
  },
})
