import React, { Component } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { buildBlocks } from "../services/builder"
import { CoreFields } from "./fragment" // eslint-disable-line
import { CoreBlockFragment } from "../components/blocks/CoreBlock" // eslint-disable-line
import RelatedArticles from "../components/RelatedArticles"

class Magazine extends Component {
  render() {
    this.props.pageContext.pageTitle = this.props.data.wpMagazine.Meta.title
      ? this.props.data.wpMagazine.Meta.title
      : this.props.data.wpMagazine.title
    this.props.pageContext.date = this.props.data.wpMagazine.date
    this.props.pageContext.cats = this.props.data.wpMagazine.categories
    this.props.pageContext.readTime = this.props.data.wpMagazine.Magazines.readingTime
    const blocks = buildBlocks(
      this.props.data.wpMagazine.blocks,
      {},
      this.props.pageContext
    )

    const relatedMagazines = this.props.data.allWpMagazine

    return (
      <Layout
        meta={{
          ...this.props.data.wpMagazine.Meta,
          ogImage: this.props.data.wpMagazine.Magazines.featuredImage?.localFile
            .childImageSharp.fixed.src,
          twitterImage: this.props.data.wpMagazine.Magazines.featuredImage
            ?.localFile.childImageSharp.fixed.src,
        }}
        path={this.props.pageContext.pagePath}
        title={this.props.data.wpMagazine.title}
        themeOptions={this.props.data.wp.themeOptions}
        pageColour={this.props.data.wpMagazine.acfPageColour.pageColour}
        featuredImage={
          this.props.data.wpMagazine.Magazines.featuredImage?.sourceUrl
        }
      >
        {blocks}
        {relatedMagazines.edges.length > 0 && (
          <>
            <RelatedArticles articles={relatedMagazines} />
            <div
              style={{
                height: 90,
                backgroundColor: "var(--page-accent)",
              }}
            />
          </>
        )}
      </Layout>
    )
  }
}

export const query = graphql`
  query($id: String, $tags: [String]) {
    wpMagazine(id: { eq: $id }) {
      id
      slug
      title
      date(formatString: "DD MMMM YYYY")
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage {
          uri
        }
      }
      Magazines {
        readingTime
        featuredImage {
          sourceUrl
          localFile {
            publicURL
            childImageSharp {
              fixed(width: 300, height: 200) {
                src
              }
              gatsbyImageData(
                width: 300
                height: 200
                layout: FIXED
                formats: [JPG, WEBP, AVIF]
              )
            }
          }
        }
      }
      acfPageColour {
        pageColour
      }
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
      editions {
        nodes {
          id
          magazines {
            nodes {
              id
              title
              uri
              acfPageColour {
                pageColour
              }
              Magazines {
                readingTime
                featuredImage {
                  sourceUrl
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
    allWpMagazine(
      filter: {
        Magazines: { personaTags: { elemMatch: { id: { in: $tags } } } }
        id: { ne: $id }
      }
    ) {
      edges {
        node {
          id
          slug
          uri
          title
          terms {
            nodes {
              slug
            }
          }
          acfPageColour {
            pageColour
          }
          Magazines {
            personaTags {
              id
            }
            featuredImage {
              sourceUrl
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 300
                    height: 200
                    layout: FIXED
                    formats: [JPG, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`

export default Magazine
